.about {
    height: auto;
    width: 100%; 
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    z-index: 1;
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;

    transition: 0.5s;
    animation: fadeEffect 0.5s;
}

.about .left {
    height: auto;
    width: 100%;
    text-align: center;
    grid-template-columns: repeat(1, 2fr);
    margin: auto;
    margin-top: 20px;
    margin-left: 40px;
    padding: 1rem;
    max-width: 80vw;
}

.about .center {
    height: auto;
    width: 90%;
    text-align: center;
    grid-template-columns: repeat(1, 2fr);
    margin: auto;
    padding: 1rem;
    max-width: 80vw;
}

.about .right {
    height: 90%;
    width: 80%;
    margin: auto;
    position: relative;;
    text-align: center;
    margin-top: 80px;
    margin-left: 90px;
}


/**LEFT**/
.left .description {
    height: auto;
}
.left .description h1 {
    margin: 1.2rem 0;
    text-align: left;
    color: rgb(0, 0, 0);
}

.left .description h2 {
    padding-top: 10px ;
    padding-bottom: 0px ;
    text-align: left;
    color: rgb(0, 0, 0);
}

.left .description h3 {
    margin: 0.5rem 0;
    text-align: left;
    color: rgb(0, 0, 0);
}

.left .description p {
    color: rgb(0, 0, 0);
    margin: 0.5rem 0;
    word-spacing: 1px;
    line-height: 25px;
    font-size: 17px;
    text-align: left;
}

/**CENTER**/
.center .description {
    text-align: center;
    height: auto;
}
.center .description h1 {
    margin: 1.2rem 0;
    text-align: left;
    color: rgb(0, 0, 0);
}

.center .description h2 {
    padding-top: 10px ;
    padding-bottom: 0px ;
    text-align: left;
    color: rgb(0, 0, 0);
}

.center .description p {

    margin: 1.2rem 0;
    text-align: left;
}


/*RIGHT*/
.right .img_container {
    /*width: 100%;*/
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: repeat(2);
    row-gap: 20px;
    position: relative;
    align-items: center;
    text-align: center;
    z-index: 1;
}

.right .img_container .img {
    max-height: 100%;
    max-width: 100%;
}

.right .img_container .top {
    /*width: 100%;*/
    grid-column: 1;
    grid-row: 1;
    z-index: 6;  
}

.right .img_container .bottom {
    /* 
    padding-bottom: 5rem;
    padding-left: 7rem;
    */
    grid-column: 1; 
    grid-row: 2;
    z-index: 5;
}


/*Tabs*/
.description .tab {
    overflow:auto;
    border: 1px solid rgb(180, 179, 179);
    border-radius: 10px;
    background-color: #9e9e9e;
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    transition: 0.5s;
    animation: fadeEffect 0.5s;
}

/* Style the buttons that are used to open the tab content */
.description .tab button {
    background-color: white;
    width: auto;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    font-size: 15px;
    transition: 0.5s;
    animation: fadeEffect 0.5s;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #9e9e9e;
  transition: 0.5s;
  animation: fadeEffect 0.5s;
}


/* Create an active/current tablink class */
.tab button.active {
    background-color: #ccc;
    transition: 0.5s;
    animation: fadeEffect 0.5s;
  }

/* Style the tab content */
.description .tabcontent {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
    animation: fadeEffect 0.7s;
    border-radius: 5px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

  /* Go from zero to full opacity */
  @keyframes fadeEffect {
    from {opacity: 0;}
    to {opacity: 1;}
  }
/*<button class="button-11" role="button">Button 11</button>

width:'100%', 
                                    border:'0px', 
                                    borderRadius:'10px', 
                                    textAlign:'center',*/

/* CSS */
.transaction_properties_container {
    width: 60%;
    display: grid;
    margin: auto;
    margin-bottom: 7px;

    font-size: small;
    text-align: center;
    grid-template-rows: repeat(4, 1fr);
}

.column {
    height: 100%;
    width: auto;
    margin: auto;
    text-align: center;
}

.field_font {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
    border-radius: 6px;
    color: #3D3D3D;
    background: #fff;
    border: none;
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: medium;
    color: #000;
}

.field_font:focus {
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
    outline: 0;
}

.transaction_properties_container label{
    font-size: large;
    padding: 1px;
}

@media screen and (max-width: 840px) {/*1140*/
    .about {
        top: 0;
        left: 0;
        margin-top: 25px;
        grid-template-columns: 1fr;
        font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
    }

    .about .img {
        max-width: 60%;
        border: 1px solid #333;
        z-index: 1
    }
    .right .img_container {
        /*width: 100%;*/
        width: 0%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        position: relative;
        align-items: center;
        text-align: center;
        padding: 0rem;
        z-index: 1;
        
        visibility: hidden;
        display: none;
    }

    .tab button {
        background-color: white;
        width: auto;
        float: left;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 14px 16px;
        transition: 0.3s;
      }


}

