.video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit:cover;
    z-index: 0;
}

.service {
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 15vh;

    z-index: 0;
    transition: 0.5s;
    animation: fadeEffect 0.5s;
}

.contentContainer {
    position: relative;
    transition: 1s;
    width: 100%;
    height: auto;

    padding-top: 2vh;
    transition: 0.5s;
    animation: fadeEffect 0.5s;
}

.contentContainer .contentServiceContainer {
    position: relative;

    width: 100%;
    text-align: center;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
   
    transition: 0.5s;
    animation: fadeEffect 0.5s;
}
.contentContainer .contentServiceContainer .left {
    position: relative;
   
    transition: 0.5s;
    animation: fadeEffect 0.5s;
    
    margin:auto;
    transition: 0.5s;
    animation: fadeEffect 0.5s;
}   
.contentContainer .contentServiceContainer .right {
    position: relative;
    margin:auto;
    
    transition: 0.5s;
    animation: fadeEffect 0.5s;
}

.contentServiceContainer .title {
    position: relative;

    padding-top: 2ch;
    padding-bottom: 2ch;

    color: black;
    transition: 1s;

    transition: 0.5s;
    animation: fadeEffect 0.5s;
}

/* Description */
.contentServiceContainer .description{
    transition: 0.5s;
    animation: fadeEffect 0.5s;
}

.description .top{
    text-align: left;
}

.description .bottom{
    align-items: center;
    text-align: center;

    transition: 0.5s;
    animation: fadeEffect 0.5s;
}

.contentServiceContainer .img_container {
    /*width: 100%;*/

    width: 100%;
    position: relative;

}

.img_container img {
    max-width:60%;
    
    transition: 0.5s;
    animation: fadeEffect 0.5s;
}
/* Font */
.p {
    font-size: 1.2rem;
    margin-bottom: 3.5rem;
    color: rgb(0, 0, 0);

    transition: 0.5s;
    animation: fadeEffect 0.5s;
}
/*.contentContainer .content .buttons{
     
    display:inline;
    margin: 100px;
    width: auto;
    max-width: 50%;

    grid-template-rows: repeat(2, 5fr);
    align-content: center;
    text-align: center;
    align-items: center;
}*/

.my_style{
    /*
    margin: 5px;
    padding: 5px;

    color: rgb(0, 0, 0);
    font-size: 20px;
    letter-spacing: 1px;
  
    border: 1px solid rgb(8, 8, 8);
    /*border-top: none;*-/

    border-radius: 5px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    transition: 0.5s;
    animation: fadeEffect 0.5s;*/


    font-family: Arial, sans-serif;
    color: #000000;
    background-color: rgba(255, 255, 255, 0.863);

    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);

    font-size: 20px;
    font-weight: 800;
    letter-spacing: 2px;

    margin: 5px;
    padding: 10px;
    
    border: 1px solid #ccc;
    /*border-top: none;*/

    border-radius: 5px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    transition: 0.5s;
    animation: fadeEffect 0.5s;
}
.my_style:hover {
    /*filter: contrast(125%);*/

    background-color: rgba(186, 186, 186, 0.863);
    color: rgb(0, 0, 0);

    transition: 0.5s;
    animation: fadeEffect 0.5s; 
}

@media screen and (max-width:1050px) {
    .service {
        margin-left: 5vw;
        margin-right: 5vw;
    
        z-index: 0;

        transition: 0.5s;
        animation: fadeEffect 0.5s;
    }
    
    .contentContainer {
        position: relative;
        transition: 1s;
        width: 100%;
        height: auto;
    
        padding-top: 2vh;
        padding-bottom: 5vh;
        margin-bottom: 2vh;
        transition: 0.5s;
        animation: fadeEffect 0.5s;
    }
    
    .contentContainer .contentServiceContainer {
        position: relative;
    
        width: 100%;
        text-align: center;
    
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 10px;
       
        transition: 0.5s;
        animation: fadeEffect 0.5s;
    }
    .img_container img {
        max-width:90%;

        transition: 0.5s;
        animation: fadeEffect 0.5s;
    }    
    /*.contentContainer .content .buttons{
     
    display: grid;
    margin: 100px;
    width: 80%;
    grid-template-rows: repeat(2, 1fr);
    align-content: center;
    text-align: center;
    align-items: center;
    }*/
    
}











