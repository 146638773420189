.content .body {

    position: relative;
    height: auto;
    width: 100%; 
    display: grid;

    padding: 80px;
    margin-bottom: 150px;
    
    text-align: left;
    
    z-index: 1;
}

.content p{
    text-align: left;
    margin-top: 2px;
    margin-left: 0px;
}

.content h2{
    text-align: left;
    color: white;
    
    margin-top: 30px;
}

.content h3{
    text-align: left;
    color: white;

    margin-top: 10px;
}

.body ul {
    margin-left: 20px; color: white; list-style-type: square;
}

.bottom {
    position: relative;
    height: auto;
    width: 100%; 
    display: grid;

    padding: 30px;
    margin-bottom: 100px;

    text-align: left;
    z-index: 1;
}

@media screen and (max-width: 1140px) {
   
    .bottom {
        position: relative;
        height: auto;
        width: 100%; 
        display: grid;
    
        padding: 30px;
        margin-bottom: 100px;
    
        text-align: left;
        z-index: 1;
    }
}

