.hero_bkg_mask {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background:  rgb(255, 255, 255);
    display: flex;
    position: fixed;
    object-fit: cover;
}

.hero_bkg {
    top: 0;
    left: 0;
    background: rgb(255, 255, 255);/*url('../assets/chart1.png')  no-repeat center center/cover;*/
    
    height: 100%;
    width: 100%;
    position:fixed;
    
    z-index: -1;
}

.heading {
    width: 100%;
    height:auto;
    margin-top: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    position: relative;

    transition: 0.5s;
    animation: fadeEffect 0.5s;

}

.heading h1 {
    color: black;
    font-size: 2.4rem;
    
}

.heading p {
    font-size: 1.4rem;
    margin-top: 10px;
    text-align: center;
    max-width: 60vw;
    color: black;

}

@media screen and (max-width:1050px) {
    .heading h1 {
        font-size: 2rem;
    }
}