.header, .header_transparent{
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 190px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 4;

    max-width: 100%;

    padding-right: 10vh;
    padding-left: 10vh;

    transition: 0.5s;
    animation: fadeEffect 0.5s;
    
}

.header_transparent {
    opacity: 0;
    
    background: rgba(250, 250, 250, 0);
    z-index: 4;
}
.hamburger {
    color: rgb(0, 0, 0);
    display: none;
    z-index: 6;
}

.nav_menu {
    display: flex;
}

.nav_menu li {
    padding: 0 1rem;
}

.nav_menu a {
    font-size: 1.2rem;
    font-weight: 500;
}

.logo_container{
    position: relative;
    display: block;
    width:15vw;
    margin-top:2vw;

    transition: 0.5s;
    animation: fadeEffect 0.5s;
}

.logo{
    top: 1vh;
    position: relative;
    width: 85%;
    border-radius: 2vh;
}

.link_black{
    color: rgb(0, 0, 0);
}

.link_white{
    color: rgb(255, 255, 255);
}

@media screen and (max-width:1340px) {
    .header, .header_transparent{
        top: 0;
        left: 0;
        position: fixed;
        width: 100%;
        height: 120px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 4;

        max-width: 100%;
    
        padding-right: 10vh;
        padding-left: 10vh;

        transition: 0.5s;
        animation: fadeEffect 0.5s;
    
    }
    
    .header_transparent {
        opacity: 0;
        
        background: rgba(250, 250, 250, 0);
        z-index: 4;
    }
    .header_transparent {
        opacity: 0;
        background: rgba(250, 250, 250, 0);
        transition: 0.1;
        z-index: 4;

    }

    .hamburger {
        display: block;
    }

    .nav_menu {
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0;
        right: -100%;
        text-align: center;
        width: 100%;
        height: 100vh;
        transition: 0.7s;
        background: white;
        z-index: 6;
    }

    .active {
        right: 0;
    }

    .link{
        color: rgb(0, 0, 0);
    }
    
    .nav_menu li {
        padding: 0.5rem;
        
    }
    .nav_menu a {
        font-size: 2rem;
        font-weight: 500;
    }
    
    /*.logo{
        top: 0vh;
        left: 2vw;
        position: absolute;
        width: auto;
        height: 100%;
    }*/
    .logo_container{
        position: relative;
        display: block;
        width:20vw;
        margin-top:2vw;

        transition: 0.5s;
        animation: fadeEffect 0.5s;
    }
    .logo{
        top: 1vh;
        position: relative;
        width: 85%;
        border-radius: 2vh;
    }

    
}

@media screen and (max-width:850px) {
    .header, .header_transparent{
        top: 0;
        left: 0;
        position: fixed;
        width: 100%;
        height: 120px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 4;
    
        max-width: 100%;
    
        padding-right: 5vh;
        padding-left: 5vh;

        transition: 0.5s;
        animation: fadeEffect 0.5s;
    
    }
    
    .header_transparent {
        opacity: 0;
        
        background: rgba(250, 250, 250, 0);
        z-index: 4;
    }
    .header_transparent {
        opacity: 0;
        background: rgba(250, 250, 250, 0);
        transition: 0.1;
        z-index: 4;
    }

    .hamburger {
        display: block;
    }

    .nav_menu {
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0;
        right: -100%;
        text-align: center;
        width: 100%;
        height: 100vh;
        transition: 0.7s;
        background: white;
        z-index: 6;
    }

    .active {
        right: 0;
    }

    .link{
        color: rgb(0, 0, 0);
    }
    
    .nav_menu li {
        padding: 0.5rem;
        
    }
    .nav_menu a {
        font-size: 2rem;
        font-weight: 500;
    }
    
    /*.logo{
        top: 0vh;
        left: 2vw;
        position: absolute;
        width: auto;
        height: 100%;
    }*/
    .logo_container{
        position: relative;
        display: block;
        width:22vw;
        margin-top:2vw;

        transition: 0.5s;
        animation: fadeEffect 0.5s;
    }
    .logo{
        top: 1vh;
        position: relative;
        width: 100%;
        border-radius: 1vh;
    }

    
}







