.label {
    color: rgb(97, 97, 97);
}

.body{
    display: flex;
    flex-direction: column;
    padding: 10vh; /*5rem;*/
    padding-top: 25px;
    margin: auto;
    padding-bottom: 150px;
    margin-bottom: 170px;
}

.body .contactsContainer{
    height: 50vh;
    width: 100%;
    display: grid;
    padding: 0vh;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 40px;
   
    margin-bottom: 150px;

    transition: 0.5s;
    animation: fadeEffect 0.5s;
}

.body h1, .body a{
    margin-bottom: 1rem;
    width: auto ;
    font-size: 1.0rem;
    font-family: 'Outfit', sans-serif;
    color: #000;
}

form {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 25px;
    margin: auto;
    padding-bottom: 200px;
    margin-bottom: 170px;
}

form .label {
    margin-bottom: .5rem;
}


form input, textarea {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 20px;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #000000;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn_my_style{
    /*
    margin: 5px;
    padding: 5px;

    color: rgb(0, 0, 0);
    font-size: 20px;
    letter-spacing: 1px;
  
    border: 1px solid rgb(8, 8, 8);
    /*border-top: none;*-/

    border-radius: 5px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    transition: 0.5s;
    animation: fadeEffect 0.5s;*/


    font-family: Arial, sans-serif;
    color: #000000;
    background-color: rgba(255, 255, 255, 0.863);

    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);

    font-size: 20px;
    font-weight: 800;
    letter-spacing: 2px;

    margin: 5px;
    padding: 10px;
    
    border: 1px solid #ccc;
    /*border-top: none;*/

    border-radius: 5px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    transition: 0.5s;
    animation: fadeEffect 0.5s;
}
.btn_my_style:hover {
    /*filter: contrast(125%);*/

    background-color: rgba(186, 186, 186, 0.589);
    color: rgb(0, 0, 0);

    transition: 0.5s;
    animation: fadeEffect 0.5s; 
}

@media screen and (max-width:1050px) {
    .body{
        display: flex;
        flex-direction: column;
        padding: 10vh; /*3rem;*/
        padding-top: 25px;
        margin: auto;
        padding-bottom: 450px;
        margin-bottom: 170px;
    }

    .body .contactsContainer{
        height: 50vh;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
       
        transition: 0.5s;
        animation: fadeEffect 0.5s;
    
    }

    .body h1, .body a{
        margin-bottom: 1rem;
        width: auto ;
        font-size: 1.0rem;
        font-family: 'Outfit', sans-serif;
        color: #000;
    }
    
}