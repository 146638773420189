.footer {
    visibility:visible;
    /*position:fixed;*/
    position:relative;

    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    min-width: fit-content;
    display: grid;
    background-color: rgba(0, 0, 0, 0.913);
    color: white;
    padding: 0.5rem 0;
    z-index: 3;
    /*transition: 1s;*/
}
.footer_transparent {
    visibility:hidden;
    /*position:fixed;*/
    position:relative;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    min-width: fit-content;
    display: grid;
    background-color: rgba(0, 0, 0, 0.913);
    color: rgba(0, 0, 0, 0);
    padding: 0.5rem 0;
    opacity: 0;
    z-index: 3;
    /*transition: 1s;*/
}

.footer_container {
    font-size: small;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-left: 20px;
}

.column {
    height: 100%;
    width: auto;
    margin: auto;
    text-align: center;
}

/*
.left {
    height: 100%;
    margin: auto;
}
.center{
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0.1rem;
    margin: auto;
}

.right {
    
}

.column {
    float: left;
    width: 25%;
    padding: 20px;

}

/* Clear floats after the columns *-/
.row:after {
    content: "";
    display: grid;
    clear: both;
   
}*/

.location {
    display: flex;
    align-items: center;
}

/* */
.footer_container h4 {
    font-size: 1.2rem;
    padding: 0.2rem 0;
}

.footer_container p {
    font-size: 0.9rem;
    padding: 0.4rem 0;
}


@media screen and (max-width:1180px) {
    .footer {
        visibility:visible;
        /*position:fixed;*/
        position:relative;
        left: 0;
        bottom: 0;
        width: 100%;
        height: auto;
        min-width: fit-content;
        display: grid;
        background-color: rgba(0, 0, 0, 0.913);
        color: white;
        padding: 0.5rem 0;
        z-index: 3;
        transition: 1s;
    }
    .footer_transparent {
        visibility:hidden;
        /*position:fixed;*/
        position:relative;
        left: 0;
        bottom: 0;
        width: 100%;
        height: auto;
        min-width: fit-content;
        display: grid;
        background-color: rgba(0, 0, 0, 0.913);
        color: rgba(0, 0, 0, 0);
        padding: 0.5rem 0;
        opacity: 0;
        z-index: 3;
        transition: 1s;
    }

    .footer_container {
        font-size: small;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        margin-left: 20px;
    }

    .column {
        height: 100%;
        width: auto;
        margin: auto;
        text-align: center;
    }

    /*
    .left {
        height: 100%;
        margin: auto;
    }
    .center{
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0.1rem;
        margin: auto;
    }
    
    .right {
        
    }
    
    .column {
        float: left;
        width: 25%;
        padding: 20px;
   
    }
    
    /* Clear floats after the columns *-/
    .row:after {
        content: "";
        display: grid;
        clear: both;
       
    }*/

    .location {
        display: flex;
        align-items: center;
    }

    /* */
    .h4 {
        font-size: 1.2rem;
        padding: 0.2rem 0;
    }

    .p {
        font-size: 0.9rem;
        padding: 0.4rem 0;
    }
}

@media screen and (max-width:840px) {
    .footer {
        visibility:visible;
        /*position:fixed;*/
        position:relative;
        left: 0;
        bottom: 0;
        width: 100%;
        height: auto;
        min-width: fit-content;
        display: grid;
        background-color: rgba(0, 0, 0, 0.913);
        color: white;
        padding: 0.5rem 0;
        z-index: 3;
        transition: 1s;
    }
    .footer_transparent {
        visibility:hidden;
        /*position:fixed;*/
        position:relative;
        left: 0;
        bottom: 0;
        width: 100%;
        height: auto;
        min-width: fit-content;
        display: grid;
        background-color: rgba(0, 0, 0, 0.913);
        color: rgba(0, 0, 0, 0);
        padding: 0.5rem 0;
        opacity: 0;
        z-index: 3;
        transition: 1s;
    }

    .footer_container {
        font-size: small;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-left: 20px;
    }

    .column {
        height: 100%;
        width: auto;
        margin: auto;
        text-align: center;
    }

    /*
    .left {
        height: 100%;
        margin: auto;
    }
    .center{
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0.1rem;
        margin: auto;
    }
    
    .right {
        
    }
    
    .column {
        float: left;
        width: 25%;
        padding: 20px;
   
    }
    
    /* Clear floats after the columns *-/
    .row:after {
        content: "";
        display: grid;
        clear: both;
       
    }*/

    .location {
        display: flex;
        align-items: center;
    }

    /* */
    .h4 {
        font-size: 1.2rem;
        padding: 0.2rem 0;
    }

    .p {
        font-size: 0.9rem;
        padding: 0.4rem 0;
    }
    

    

    
}