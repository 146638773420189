.video {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.home {
    height: 100%;
    width: 100%;
    z-index: 0;
    box-shadow: inset 0 0 0 20000px rgba(255, 255, 255, 1);
    padding:10vh;
    padding-top: 20vh;
    position: relative; 

    transition: 0.5s;
    animation: fadeEffect 0.5s;
}

.contentContainer {
    /*height: 100%;
    width: 100%;
    position: relative;
    transition: 1s;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0px;*/
    width: 100%;  
    height: 150vh;
    height: calc(1 * (85vw));

    position: relative; 
}

.contentContainer .left{
    width: 60%; 
    position: absolute;
    left: 0px;
    top: 0px;
}

.contentContainer .right{
    width: 35%; 
    height: 100%;  
    position: absolute;
    right: 0px;
    top: 0px;
}

.contentContainer .content {
    position: relative;
    text-align: center;
    box-shadow: inset 0 0 0 20000px rgb(255, 255, 255);

    transition: 0.5s;
    animation: fadeEffect 0.5s;
}
.contentContainer .title{
    margin-top: 40px;
}

.contentContainer .content .rcorners {
    border-radius: 25px;
    border: 2px solid #838383;
    color: #000;
}

.contentContainer .content .gridContent{

    height: 50%;
    display: grid;
    padding: 5vh;
    grid-template-columns: repeat(0, 0fr);
    column-gap: 40px;
}
.contentContainer .content .gridContent3{

    display: grid;
    padding-top: 20px;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;
}

.contentContainer .content .gridContent4{

    display: grid;
    padding-top: 20px;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 40px;
}

.contentContainer .buttons{
    display: grid;
    
    grid-template-columns: repeat(1, 1fr);
    column-gap: 40px;
}

.contentContainer .content .description{

    padding: 10px;
    display: grid;
    row-gap: 10px;
    grid-template-rows: auto;
    box-shadow: inset 0 0 0 20000px rgba(86, 86, 86, 0.0);
    
}

.contentContainer h1 {
    text-align: left;
    font-size: 1.5rem;
    color: rgb(0, 0, 0);

    transition: 0.5s;
    animation: fadeEffect 0.5s;
}
.contentContainer h2 {
    text-align: left;
    font-size: 1.5rem;
    color: rgb(0, 0, 0);

    transition: 0.5s;
    animation: fadeEffect 0.5s;
}
.contentContainer  a {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
    color: rgb(0, 0, 0);

    transition: 0.5s;
    animation: fadeEffect 0.5s;
}

.img {
    display:block;
    margin:auto;
    width: 80%;
    max-height: 100%;
    max-width: 80%;
}
/*.contentContainer .content .buttons{
     
    display:inline;
    margin: 100px;
    width: auto;
    max-width: 50%;

    grid-template-rows: repeat(2, 5fr);
    align-content: center;
    text-align: center;
    align-items: center;
}*/

.my_style{
    
    /*margin: 5px;
    padding: 10px;

    color: rgb(0, 0, 0);
    font-size: 20px;
    letter-spacing: 1px;
  
    border: 1px solid rgb(8, 8, 8);
    /*border-top: none;*-/

    border-radius: 5px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    transition: 0.5s;
    animation: fadeEffect 0.5s;*/


    font-family: Arial, sans-serif;
    color: #000000;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);

    font-size: 16px;
    font-weight: 800;
    letter-spacing: 2px;

    margin: 5px;
    padding: 10px;
    
    border: 1px solid #ccc;
    /*border-top: none;*/

    border-radius: 5px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    transition: 0.5s;
    animation: fadeEffect 0.5s;
}
.my_style:hover {
    /*filter: contrast(125%);*/

    background-color: rgba(186, 186, 186, 0.863);
    color: rgb(0, 0, 0);

    transition: 0.5s;
    animation: fadeEffect 0.5s; 
}
  

.linkContainer{
    display: grid;
    width: auto;

    grid-template-rows: repeat(6, 1fr);
    align-content: center;
    text-align: center;
    align-items: center;
}

.subLinkContainer{
    padding-left: 20px;
    display: grid;
    width: auto;

    grid-template-rows: repeat(4, 5fr);
    align-content: center;
    text-align: center;
    align-items: center;
}
@media screen and (max-width:1140px) {
    .home {
        height: 100%;
        width: 100%;
        z-index: 0;
        box-shadow: inset 0 0 0 20000px rgba(255, 255, 255, 1);
        padding:10vh;
        transition: 0.5s;
        animation: fadeEffect 0.5s;
    }

    .contentContainer {
    height: calc(-1 * (5rem - 65rem));
    }
    
    .contentContainer h1 {
        text-align: left;
        font-size: 1.25rem;
        color: rgb(0, 0, 0);
    
        transition: 0.5s;
        animation: fadeEffect 0.5s;
    }
    .contentContainer h2 {
        text-align: left;
        font-size: 1.25rem;
        color: rgb(0, 0, 0);
    
        transition: 0.5s;
        animation: fadeEffect 0.5s;
    }
    .contentContainer  a {
        text-align: center;
        font-size: 1rem;
        font-weight: 400;
        
        color: rgb(0, 0, 0);
    
        transition: 0.5s;
        animation: fadeEffect 0.5s;
    }
    
}

@media screen and (max-width:850px) {
    .home {
        height: 100%;
        width: 100%;
        z-index: 0;
        box-shadow: inset 0 0 0 20000px rgba(255, 255, 255, 1);
        padding:5vh;

        transition: 0.5s;
        animation: fadeEffect 0.5s;
    }

    .contentContainer {
        height: calc(-1 * (5rem - 65rem));
    }

    .contentContainer h1 {
        text-align: left;
        font-size: 1.0rem;
        color: rgb(0, 0, 0);
    
        transition: 0.5s;
        animation: fadeEffect 0.5s;
    }

    .contentContainer h2 {
        text-align: left;
        font-size: 0.7rem;
        color: rgb(0, 0, 0);
    
        transition: 0.5s;
        animation: fadeEffect 0.5s;
    }
    .contentContainer  a {
        text-align: center;
        font-size: 0.85rem;
        font-weight: 400;
        
        color: rgb(0, 0, 0);
    
        transition: 0.5s;
        animation: fadeEffect 0.5s;
    }
    .contentContainer  a.contact{
        font-size: 0.65rem;        
    }
    
}











